<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section padd-30 pt-0">
              <div class="back-btn mt-0 mr-2">
                <router-link :to="{ name: 'menu_items',  params: { id: id } }"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
              </div> 
              <div class="w-100 t-wrap">
                <h4 class="menu_header">Menu Item</h4>
              </div>
  
                
            </div>
            <hr>
            <div class="create_items_section">
              <form @submit.prevent="createMenuItem">
                <div v-if="errors !=''" >
                  <div class="alert alert-danger">
                    <ul>
                      <li v-for="(err_msg,errindex) in errors" :key="errindex">{{err_msg}}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="pl-5 pr-4 pb-4">
                      <div class="setting_content">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="name">Name(route name):</label>
                            <div class="col-sm-7">
                              <div>
                                <!-- <select class="form-control" id="" v-model="routeName" >
                                  <option value="">--Select Route Name--</option>
                                  <option v-for="route in routes" :key="route.name" :value="route" >{{ route.displayname }}</option>
                                </select> -->

                                <Multiselect v-model="name" :close-on-select="true"
                                  :options="routeOptions" :custom-label="name" :searchable="true"
                                  :placeholder="'--Select Route Name--'" label="name" track-by="name">
                                </Multiselect>
                                <!-- <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch"> -->
                              </div>
                              <div class="input_error" v-if="v$.name.$error">
                                <span v-if="v$.name.required.$invalid">Name is required</span>
                              </div>
  
                             
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Display Name:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="displayName" @blur="v$.displayName.$touch"/>
                              <div class="input_error" v-if="v$.displayName.$error">
                                <span v-if="v$.displayName.required.$invalid">Display Name is required</span>
                              </div>
                            </div>
                          </div>
                        </div>   

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Icon:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="icon" @blur="v$.icon.$touch"/>
                              
                            </div>
                          </div>
                        </div>  
                        
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-md-5" for="mbl_type">Status:</label>
                            <div class="col-md-7">
                              <select class="form-control" v-model="status" >
                                <option value="1">Published</option>
                                <option value="2">Unpublished</option>
                              </select>
                                 
                            </div>
                          </div>
                          
                        </div>

                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret"></label>
                            <div class="col-sm-7">
                              <button type="button" class="btn btn-default custom_btn custon_orange" data-toggle="modal" data-target="#new_menu">
                                <i class="fa fa-plus"></i> Add Sub Menu Items
                              </button>
                              <!-- <input type="text" class="form-control" v-model="slug" @blur="v$.slug.$touch"/> -->
                             
                            </div>
                          </div>
                          <!-- modal -->
                          <div class="modal fade" id="new_menu" role="dialog">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">Create Sub Menu Item
                                </div>
                                <form @submit.prevent="createChildMenu()">
                                  <div class="modal-body">
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Name:</label>
                                      <div class="mt-3">
                                        <!-- <select class="form-control" id="" v-model="cname">
                                          <option value="">--Select Route Name--</option>
                                          <option v-for="route in routes" :key="route.name" :value="route.name">{{ route.displayname }}</option>
                                        </select> -->

                                        <Multiselect v-model="cname" :close-on-select="true"
                                          :options="routeOptions" :custom-label="name" :searchable="true"
                                          :placeholder="'--Select Route Name--'" label="name" track-by="name">
                                        </Multiselect>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Display Name:</label>
                                      <div class="mt-3">
                                        <input type="text" class="form-control" v-model="cdisplayName">
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Icon:</label>
                                      <div class="mt-3">
                                        <input type="text" class="form-control" v-model="cicon">
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="control-label" for="mbl_type">Status:</label>
                                      <div class="mt-3">
                                        <select class="form-control" v-model="cstatus" >
                                          <option value="1">Published</option>
                                          <option value="2">Unpublished</option>
                                        </select>

                                 
                                      </div>
                                    </div>
                                    
                                  </div>
                            
                                  <div class="modal-footer">
                                    <button type="button" id="create_close_btn" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                    <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Create</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <!-- endmodal -->
                        </div>   


                        <!--  -->
                      </div>
                    </div>
                  </div>
                  <!--  -->
                </div>
                <!--  -->
                <div v-if="childItems.length >0 " class="row bt-b">
                  <div class="col-md-12">
                    <div class="pl-5 pr-4 pb-4">
                      <h6>Sub menu items:</h6>
                      <div>
                        <table class="table  table-hover">
                          <thead>
                            <th>Name</th>
                            <th>Display Name</th>
                            <th>icon</th>
                            <th>Status</th>
                            <th>Action</th>
                          </thead>
                          <tbody>
                            <tr v-for="childitem in childItems" :key="childitem.name">
                              <td>
                                {{ childitem.name }}
                              </td>
                              <td>
                                {{ childitem.display_name }}
                              </td>
                              <td>
                                <span v-html="childitem.icon"></span>
                              </td>
                              <td>
                                <span v-if="childitem.status == 1" class="active"> Published</span>
                                <span v-if="childitem.status == 2" class="disabled"> Unpublished</span>
                              </td>
                              <td>
                                <span @click="removeMenuSubitem(childitem.name)" class="remove_icon glyphicon glyphicon-remove"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> 
                  </div>
                </div>
                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-2r mt-3">
                      <button type="submit" class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span></button>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { useToast } from "vue-toastification";
  import navrouters from '@/mixins/routes';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import Multiselect from '@vueform/multiselect';
  const toast = useToast();
  export default {
        components: {
                Multiselect
        },
     
      setup() {
          return { v$: useVuelidate() }
      },
      data() {
          return {
              name: '',
              displayName:'',
              status: 1,
              icon: '',
              cname: '',
              cdisplayName:'',
              cstatus: 1,
              cicon: '',
              errors: '',
              spinner: false,
              id: this.$route.params.id,
              searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes...",
              childItems: [],
              routes: navrouters.routes,
              routeOptions:[]
          }
      },
      mounted() {
        for(let i=0; i < navrouters.routes.length; i++){
          this.routeOptions.push({
                "value": navrouters.routes[i].name,
                "name": navrouters.routes[i].displayname
          })

        }

       
    },
     
      validations() {
          return {
              name: { required },
              displayName: { required },
          }
      },
      methods: {
        selectMenuName(menu){
            this.displayName = menu.displayName
        },
        selectChildMenuName(menu){
            this.cdisplayName = menu.displayName
        },
        removeMenuSubitem(name){
            this.childItems =   this.childItems.filter((x) => x.name != name)
        },
          createChildMenu(){
           const childmenuItem =  this.childItems.filter((x) => x.name == this.cname)
           if(childmenuItem.length == 0){
              this.childItems.push({
                'name': this.cname,
                'display_name': this.cdisplayName,
                'icon': this.cicon,
                'status': this.cstatus
              })
           }
           document.getElementById('create_close_btn').click();
          },
          async createMenuItem() {
              this.spinner = true;
              const isFormCorrect = await this.v$.$validate()
              if (!isFormCorrect) {
                  this.spinner = false;
                  return
              }
              this.errors = '';
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = `${this.dashBaseUrl}/create-menu-item/${this.id}/${this.merchantID}`;
              const payload = {
                  'name': this.name,
                  'slug': this.slug,
                  'merchant_id': parseInt(this.merchantID),
                  'display_name': this.displayName,
                  'icon': this.icon,
                  'status': this.status,
                  'child_menus': this.childItems
              }
              this.axios.post(url,payload,config).then((response)=>{
                  if(response.status === 201){
                      this.spinner = false;
                      toast.success('role created successfully');
                      this.$router.push({
                          name: 'menu_items',
                          params: { id: this.id }
                      })
                      
                  }
              }).catch((error)=>{
                  this.spinner =false;
                  if(error.response.status === 400){
                      this.errors = error.response.data.errors
                  }else if(error.response.status === 401){
                      this.$router.push({
                          name: 'login'
                      });
                  }
              });
  
          },
      },
      mixins: [ReusableDataMixins]
  }
  </script>
  